export const detections = {
  classes: {
    aeroplane: 'Aeroplane',
    aeroplanePl: 'Aeroplanes',
    apparel: 'Apparel',
    apparelPl: 'Apparels',
    apple: 'Apple',
    applePl: 'Apples',
    backpack: 'Backpack',
    backpackPl: 'Backpacks',
    bag: 'Bag',
    bagPl: 'Bags',
    banana: 'Banana',
    bananaPl: 'Bananas',
    barrier: 'Barrier',
    barrierPl: 'Barriers',
    baseballbat: 'Baseball Bat',
    baseballbatPl: 'Baseball Bats',
    baseballglove: 'Baseball Glove',
    baseballglovePl: 'Baseball Gloves',
    bear: 'Bear',
    bearPl: 'Bears',
    bed: 'Bed',
    bedPl: 'Beds',
    bench: 'Bench',
    benchPl: 'Benchs',
    bicycle: 'Bicycle',
    bicyclePl: 'Bicycles',
    bird: 'Bird',
    birdPl: 'Birds',
    boat: 'Boat',
    boatPl: 'Boats',
    book: 'Book',
    bookPl: 'Books',
    bottle: 'Bottle',
    bottlePl: 'Bottles',
    bowl: 'Bowl',
    bowlPl: 'Bowls',
    box: 'Box',
    boxPl: 'Boxes',
    broccoli: 'Broccoli',
    broccoliPl: 'Broccolis',
    bulldozer: 'Bulldozer',
    bus: 'Bus',
    busPl: 'Buses',
    cake: 'Cake',
    cakePl: 'Cakes',
    car: 'Car',
    carPl: 'Cars',
    carrot: 'Carrot',
    carrotPl: 'Carrots',
    cat: 'Cat',
    catPl: 'Cats',
    cellphone: 'Cellphone',
    cellphonePl: 'Cellphones',
    chair: 'Chair',
    chairPl: 'Chairs',
    clock: 'Clock',
    clockPl: 'Clocks',
    cone: 'Cone',
    conePl: 'Cones',
    cow: 'Cow',
    cowPl: 'Cows',
    cup: 'Cup',
    cupPl: 'Cupes',
    deliverybag: 'Delivery Bag',
    deliverybagPl: 'Delivery Bags',
    diningtable: 'Dining Table',
    diningtablePl: 'Dining Tables',
    document: 'Document',
    documentPl: 'Documents',
    dog: 'Dog',
    dogPl: 'Dogs',
    donut: 'Dunut',
    donutPl: 'Dunuts',
    elephant: 'Elephant',
    elephantPl: 'Elephants',
    equipment: 'Equipment',
    equipmentPl: 'Equipments',
    excavator: 'Excavator',
    face: 'Face',
    facePl: 'Faces',
    firehydrant: 'Fire Hydrant',
    firehydrantPl: 'Fire Hydrants',
    'flatbed-trailer': 'Flatbed Trailer',
    fork: 'Fork',
    forklift: 'Forklift',
    forkPl: 'Forks',
    frisbee: 'Frisbee',
    frisbeePl: 'Frisbees',
    giraffe: 'Giraffe',
    giraffePl: 'Giraffes',
    guard: 'Guard',
    guardPl: 'Guards',
    hairdrier: 'Hair Drier',
    hairdrierPl: 'Hair Driers',
    handbag: 'Handbag',
    handbagPl: 'Handbags',
    head: 'Head',
    headPl: 'Heads',
    horse: 'Horse',
    horsePl: 'Horses',
    hotdog: 'Hot Dog',
    hotdogPl: 'Hot Dogs',
    inspector: 'Inspector',
    inspectorPl: 'Inspectors',
    keyboard: 'Keyboard',
    keyboardPl: 'Keyboards',
    kite: 'Kite',
    kitePl: 'Kites',
    knife: 'Knife',
    knifePl: 'Knifes',
    label: 'Label',
    labelPl: 'Labels',
    laptop: 'Laptop',
    laptopPl: 'Laptops',
    license_label: 'License',
    license_labelPl: 'Licenses',
    license_plate: 'License',
    license_platePl: 'Licenses',
    machinery: 'Machinery',
    mask: 'Face Mask',
    maskPl: 'Face Masks',
    microwave: 'Microwave',
    microwavePl: 'Microwaves',
    motorbike: 'Motorbike',
    motorbikePl: 'Motorbikes',
    mouse: 'Mouse',
    mousePl: 'Mouses',
    orange: 'Orange',
    orangePl: 'Oranges',
    oven: 'Oven',
    ovenPl: 'Ovens',
    parkingmeter: 'Parking Meter',
    parkingmeterPl: 'Parking Meters',
    person: 'Person',
    personPl: 'Persons',
    pet: 'Pet',
    pistol: 'Pistol',
    pistolPl: 'Pistols',
    pizza: 'Pizza',
    pizzaPl: 'Pizzas',
    pottedplant: 'Potted Plant',
    pottedplantPl: 'Potted Plants',
    refrigerator: 'Refrigerator',
    refrigeratorPl: 'Refrigerators',
    remote: 'Remote',
    remotePl: 'Remotes',
    sandwich: 'Sandwich',
    sandwichPl: 'Sandwiches',
    scissors: 'Scissors',
    scissorsPl: 'Scissors',
    scooter: 'Scooter',
    scooterPl: 'Scooters',
    sheep: 'Sheep',
    sheepPl: 'Sheeps',
    shoebox: 'Shoebox',
    shoeboxPl: 'Shoe boxes',
    sink: 'Sink',
    sinkPl: 'Sinks',
    skateboard: 'Skateboard',
    skateboardPl: 'Skateboards',
    skis: 'Skis',
    skisPl: 'Skis',
    sneakers: 'Sneakers',
    sneakersPl: 'Sneakers',
    snowboard: 'Snowboard',
    snowboardPl: 'Snowboards',
    socks: 'Socks',
    socksPl: 'Socks',
    sofa: 'Sofa',
    sofaPl: 'Sofas',
    spoon: 'Spoon',
    spoonPl: 'Spoons',
    sportsball: 'Sports ball',
    sportsballPl: 'Sports balls',
    stopsign: 'Stop Sign',
    stopsignPl: 'Stop Signs',
    suitcase: 'Suitcase',
    suitcasePl: 'Suitcases',
    surfboard: 'Surfboard',
    surfboardPl: 'Surfboards',
    teddybear: 'Teddy Bear',
    teddybearPl: 'Teddy Bears',
    tennisracket: 'Tennis Racket',
    tennisracketPl: 'Tennis Rackets',
    tie: 'Tie',
    tiePl: 'Ties',
    toaster: 'Toaster',
    toasterPl: 'Toasters',
    toilet: 'Toilet',
    toiletPl: 'Toilets',
    toothbrush: 'Toothbrush',
    toothbrushPl: 'Toothbrushes',
    trafficlight: 'Traffic Light',
    trafficlightPl: 'Traffic Lights',
    train: 'Train',
    trainPl: 'Trains',
    trashbin: 'Trashbin',
    trashbinPl: 'Trash bins',
    truck: 'Truck',
    truckPl: 'Trucks',
    tvmonitor: 'TV',
    tvmonitorPl: 'TVs',
    umbrella: 'Umbrella',
    umbrellaPl: 'Umbrellas',
    vase: 'Vase',
    vasePl: 'Vases',
    wineglass: 'Wine Glass',
    wineglassPl: 'Wine Glasses',
    zebra: 'Zebra',
    zebraPl: 'Zebras',
    helmet: 'Helmet',
    helmetPl: 'Helmets',
    red_light: 'Red Light',
    red_lightPl: 'Red Lights',
    green_light: 'Green Light',
    green_lightPl: 'Green Lights',
    yellow_light: 'Yellow Light',
    yellow_lightPl: 'Yellow Lights',
    cart: 'Cart',
    cartPl: 'Carts',
    fire: 'Fire',
    firePl: 'Fires',
    smoke: 'Smoke',
    smokePl: 'Smoke',
    material: 'Material',
    materialPl: 'Materials',
    hopper: 'Hopper',
    hopperPl: 'Hoppers',
    truck_small: 'Small Truck',
    truck_smallPl: 'Small Trucks',
    aljibe: 'Aljibe',
    aljibePl: 'Aljibes',
    mercaderia: 'Merchandise',
    mercaderiaPl: 'Merchandise',
    rock: 'Rock',
    rockPl: 'Rocks',
    desmonte: 'Desmonte',
    desmontePl: 'Desmonte',
    pallet: 'Pallet',
    palletPl: 'Pallets',
    'pallet-jack': 'Pallet Jack',
    'pallet-jackPl': 'Pallet Jacks',
    'pallet-stacker': 'Pallet Stacker',
    'pallet-stackerPl': 'Pallet Stackers',
    worker: 'Worker',
    workerPl: 'Workers',
    cat1: 'Category 1',
    cat1Pl: 'Category 1',
    cat2: 'Category 2',
    cat2Pl: 'Category 2',
    cat3: 'Category 3',
    cat3Pl: 'Category 3',
    cat4: 'Category 4',
    cat4Pl: 'Category 4',
    cylinder: 'Cylinder',
    cylinderPl: 'Cylinders',
    extinguisher: 'Extinguisher',
    extinguisherPl: 'Extinguishers',
    crane: 'Crane',
    cranePl: 'Cranes',
    montacilindro: 'Montacilindro',
    montacilindroPl: 'Montacilindros',
    no_helmet: 'No Helmet',
    no_helmetPl: 'No Helmets',
    gate: 'Gate',
    gatePl: 'Gates',
    text: 'Text',
    textPl: 'Texts',
    container: 'Container',
    containerPl: 'Containers',
  },
  poses: {
    NONE: 'Unrecognized',
    HANDS_UP: 'Hands Up',
    ON_FLOOR: 'On Floor',
    RUNNING: 'Running',
    SEATED: 'Seated',
    STANDING: 'Standing',
    FIGHT: 'Fighting',
    THROWING_THINGS: 'Throwing things',
    points: {
      nose: 'nose',
      l_eye: 'l_eye',
      r_eye: 'r_eye',
      l_ear: 'l_ear',
      r_ear: 'r_ear',
      l_shoulder: 'l_shoulder',
      r_shoulder: 'r_shoulder',
      l_elbow: 'l_elbow',
      r_elbow: 'r_elbow',
      l_wrist: 'l_wrist',
      r_wrist: 'r_wrist',
      l_hip: 'l_hip',
      r_hip: 'r_hip',
      l_knee: 'l_knee',
      r_knee: 'r_knee',
      l_ankle: 'l_ankle',
      r_ankle: 'r_ankle',
      neck: 'neck',
    },
  },
  faces: {
    noMask: 'Without mask',
    withMask: 'With mask',
  },
  missingObject: {
    helmet: 'No helmet',
    lincense_plate: 'No license plate',
  },
};
