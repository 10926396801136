import '@total-typescript/ts-reset/json-parse';

import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { AuthContextProvider } from './contexts/AuthContextProvider';
import './styles/App.scss';
import LanguageProvider from './contexts/LanguageProvider';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { currentCustomerLoader } from './services/routerLoaders/currentCustomerLoader';
import { currentProjectLoader } from './services/routerLoaders/currentProjectLoader';
import { lazy, useEffect } from 'react';
import { Root } from './pages/Root';
import queryClient from './services/GlobalQueryClient';
import ThemeProvider from './contexts/ThemeProvider';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { registerIframeListeners } from './utils/iframeUtils';
import PageNotFound from './pages/PageNotFound';
import { currentVarLoader } from './services/routerLoaders/currentVarLoader';
import Error from './pages/Error';

const AssetSelector = lazy(
  () => import('./pages/ForensicReconstruction/AssetSelector')
);
const ForensicReconstruction = lazy(
  () => import('./pages/ForensicReconstruction/ForensicReconstruction')
);
const ForensicReconstructionRoot = lazy(
  () => import('./pages/ForensicReconstruction/ForensicReconstructionRoot')
);
const LicensePlatesSearch = lazy(
  () => import('./pages/LicensePlatesSearch/LicensePlatesSearch')
);
const Blacklist = lazy(() => import('./pages/LicensePlatesSearch/Blacklist'));
const Storylines = lazy(() => {
  return import('./pages/Storylines/Storylines');
});
const StorylineFullView = lazy(
  () => import('./pages/Storylines/StorylineFullView')
);

const CreateStorypoint = lazy(
  () => import('./pages/Storylines/CreateStorypoint')
);
const EditStorypoint = lazy(() => import('./pages/Storylines/EditStorypoint'));
const CreateStoryline = lazy(
  () => import('./pages/Storylines/CreateStoryline')
);
const EditStoryline = lazy(() => import('./pages/Storylines/EditStoryline'));
const ManageCategories = lazy(
  () => import('./pages/Storylines/ManageCategories')
);
const StorylineDownloadPreview = lazy(
  () => import('./pages/Storylines/StorylineDownloadPreview')
);
//const EventReport = lazy(() => import('./pages/CountReport/CountReport'));
const DetectionsHistory = lazy(
  () => import('./pages/DetectionsHistory/DetectionsHistory')
);

const CountReport = lazy(() => import('./pages/CountReport/CountReport'));
const CountReportDownloadPreview = lazy(
  () => import('./pages/CountReport/CountReportDownloadPreview')
);

const CargoContaingerLog = lazy(
  () => import('./pages/CargoContainerLog/CargoContainerLog')
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    id: 'root',
    loader: currentVarLoader,
    children: [
      {
        path: ':customerId',
        element: <Outlet />,
        loader: currentCustomerLoader,
        id: 'current-customer',
        children: [
          {
            path: 'p',
            element: <Outlet />,
            id: 'projects',
            children: [
              {
                path: ':projectId',
                element: <Outlet />,
                loader: currentProjectLoader,
                id: 'current-project',
                children: [
                  {
                    path: 'forensic-reconstruction',
                    Component: ForensicReconstructionRoot,
                    children: [
                      {
                        path: '',
                        Component: ForensicReconstruction,
                      },
                      {
                        path: 'asset-selector',
                        Component: AssetSelector,
                      },
                    ],
                  },
                  {
                    path: 'storylines',
                    element: <Outlet />,
                    id: 'storylines',
                    children: [
                      {
                        path: '',
                        Component: Storylines,
                      },
                      {
                        path: 'categories',
                        Component: ManageCategories,
                      },
                      {
                        path: 'create',
                        Component: CreateStoryline,
                      },

                      {
                        path: ':storylineId',
                        element: <Outlet />,
                        children: [
                          {
                            path: '',
                            Component: StorylineFullView,
                          },
                          {
                            path: 'edit',
                            Component: EditStoryline,
                          },
                          {
                            path: 'download',
                            Component: StorylineDownloadPreview,
                          },
                          {
                            path: 'storypoints',
                            element: <Outlet />,
                            children: [
                              {
                                path: 'create',
                                Component: CreateStorypoint,
                              },
                              {
                                path: ':storypointId/edit',
                                Component: EditStorypoint,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'license-plates-search',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        Component: LicensePlatesSearch,
                      },
                      {
                        path: 'blacklist',
                        Component: Blacklist,
                      },
                    ],
                  },
                  {
                    path: 'cargo-container-log',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        Component: CargoContaingerLog,
                      },
                    ],
                  },
                  {
                    path: 'granularity-dashboard',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        Component: lazy(
                          () =>
                            import(
                              './pages/GranularityDashboard/GranularityDashboard'
                            )
                        ),
                      },
                    ],
                  },
                  {
                    path: 'count-report',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        Component: CountReport,
                      },
                      {
                        path: 'downloadPDF',
                        Component: CountReportDownloadPreview,
                      },
                    ],
                  },
                  {
                    path: 'detections-history',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        Component: DetectionsHistory,
                        children: [
                          {
                            path: ':assetId',
                            element: <Outlet />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

function App() {
  useEffect(() => {
    registerIframeListeners();
  }, []);

  return (
    <ThemeProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#707EDC',
          },
          components: {
            Slider: {
              trackBg: '#707EDC',
              trackHoverBg: '#8697E5',
              handleColor: '#8697E5',
              handleActiveColor: '#C8DCFE',
              dotActiveBorderColor: '#8697E5',
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <AuthContextProvider>
              <RouterProvider router={router} />
            </AuthContextProvider>
          </LanguageProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
