import { Colors } from './en/Colors';
import { detections } from './en/Detections';
import { Errors } from './en/Errors';
import { ForensicReconstruction } from './en/ForensicReconstruction';
import { LicensePlatesSearch } from './en/LicensePlatesSearch';
import { Status } from './en/Status';
import { Storylines } from './en/Storylines';
import { GranularityDashboard } from './en/GranularityDashboard';
import { CountReport } from './en/CountReport';
import { DetectionsHistory } from './en/DetectionsHistory';
import { countries } from './en/Countries';

const en = {
  general: {
    from: 'From',
    to: 'To',
    accuracy: 'Accuracy',
    add: 'Add',
    additionalInformation: 'Additional information',
    address: 'Address',
    allFP: 'All',
    and: 'and',
    applyFilters: 'Apply filters',
    attachFiles: 'Attach Files',
    attachments: 'Attachments',
    author: 'Author',
    cancel: 'Cancel',
    category: 'Category',
    characters: 'Characters',
    checkAll: 'Check all',
    clearFilters: 'Clear filters',
    clearSearch: 'Clear search',
    close: 'Close',
    copyToClipboard: 'Copy to clipboard',
    creationDate: 'Creation date',
    customF: 'Custom',
    date: 'Date',
    debuggingMode: 'Debugging mode',
    detections: 'Detections',
    delete: 'Delete',
    description: 'Description',
    deselect: 'Deselect',
    download: 'Download',
    downloadFile: 'Download file',
    downloadImage: 'Download image',
    downloadImages: 'Download images',
    downloadRawData: 'Download raw data',
    edit: 'Edit',
    editMode: 'Edit mode',
    elements: 'Elements',
    example: 'Example',
    exportAsPDF: 'Export as PDF',
    filter: 'Filter',
    goBack: 'Go back',
    height: 'Height',
    hide: 'Hide',
    hideLabels: 'Hide labels',
    images: 'Images',
    languages: {
      english: 'English',
      spanish: 'Spanish',
    },
    length: 'Length',
    load: 'Load',
    loading: 'Loading',
    location: 'Location',
    login: 'Login',
    maximum: 'Máximum',
    minimum: 'Minimum',
    name: 'Name',
    next: 'Next',
    notAuthenticated: 'Authentication required',
    preview: 'Preview',
    previous: 'Previous',
    probability: 'Probability',
    recommended: 'Recommended',
    required: 'Required',
    reset: 'Reset',
    results: 'Results',
    reload: 'Reload',
    save: 'Save',
    saveChanges: 'Save changes',
    search: 'Search',
    searchNoun: 'Search',
    seeAttachments: 'See attachments',
    seeSelectedImages: 'See selected images',
    seeDetails: 'See details',
    selectedImages: 'Selected images',
    selectCategory: 'Select category',
    selected_LC_F: 'selected',
    selected_LC_M: 'selected',
    selectLocation: 'Select location',
    show: 'Show',
    showLabels: 'Show labels',
    sort: 'Sort',
    status: 'Status',
    surname: 'Surname',
    switchToDarkTheme: 'Switch to dark theme',
    switchToLightTheme: 'Switch to light theme',
    time: 'Time',
    timeInterval: 'Time interval',
    type: 'Type',
    user: 'User',
    username: 'Username',
    userP: 'Users',
    videos: 'Videos',
    yes: 'Yes',
    Messages: {
      browseOrDropFilesToUpload: 'Browse or drag and drop files to upload.',
      fileSizeMayNotExceed: 'Total file size may not exceed',
      fileSizeExceeded: 'File size exceeded.',
      addABriefDescription: 'Add a brief description',
      noResultsFound: 'No results found',
      noSelectedElements: 'No selected elements.',
      weAreAboutToRedirectYouTo: "We're about to redirect you to",
      doYouWishToContinue: 'Do you wish to continue?',
      error404: 'Sorry, the page you visited does not exist.',
      notAuthorised: 'Sorry, you are not authorised to access this page.',
      thisViewIsNotYetAvailableOnMobile:
        'This view is not yet available on mobile',
      viewItOnDesktopInstead: 'View it on desktop instead',
      noBlankSpacesOrSpecialCharactersAllowed:
        'No blank spaces or special characters allowed.',
    },
    Errors: Errors,
  },
  ForensicReconstruction: ForensicReconstruction,
  Storylines: Storylines,
  LicensePlatesSearch: LicensePlatesSearch,
  GranularityDashboard: GranularityDashboard,
  CountReport: CountReport,
  DetectionsHistory: DetectionsHistory,
  detections: detections,
  status: Status,
  colors: Colors,
  countries: countries,
};

export default en;
